import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import { collection, doc, setDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { DB } from 'src/auth/FirebaseContext';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';
import Iconify from 'src/components/iconify/Iconify';

type FormValuesProps = {
  email: string;
  phone: string;
};

interface CreateIgnoredContactProps {
  addIgnoredContactModalOpen: boolean;
  handleModalClose: VoidFunction;
}

export default function CreateIgnoredContact({
  addIgnoredContactModalOpen,
  handleModalClose,
}: CreateIgnoredContactProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [ignoredContactLoading, setIgnoredContactLoading] = useState(false);

  const createIgnoredContactSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    phone: Yup.string()
      .required('Phone Number is required.')
      .matches(/^\d{10}$/, 'Must be a US number with exactly 10 digits'),
  });

  const defaultValues = {
    email: '',
    phone: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(createIgnoredContactSchema),
    defaultValues,
  });

  const { reset, handleSubmit } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    setIgnoredContactLoading(true);
    const ignoredContactData = { email: data.email, phone: data.phone };

    try {
      const docRef = doc(collection(DB, 'ignoredContacts'));

      await setDoc(docRef, { ...ignoredContactData, id: docRef.id });
      enqueueSnackbar('Contact Added Successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error.response.data || 'Contact Adding Failed', { variant: 'error' });
    } finally {
      handleModalClose();
      setIgnoredContactLoading(false);
      reset();
    }
  };

  const resetModalData = () => {
    handleModalClose();
    reset();
  };

  return (
    <Dialog open={addIgnoredContactModalOpen} onClose={resetModalData}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pt: 1.4,
          pr: 0.3,
          minWidth: '500px !important',
        }}
      >
        Add Contact
        <IconButton onClick={resetModalData}>
          <Iconify icon="radix-icons:cross-2" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container justifyContent="space-between">
            <Grid item md={12}>
              <RHFTextField name="email" label="Email" sx={{ mt: '10px' }} />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item md={12}>
              <RHFTextField name="phone" label="Phone" sx={{ mt: '10px' }} />
            </Grid>
          </Grid>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton
              variant="contained"
              loading={ignoredContactLoading}
              type="submit"
              sx={{ my: 3 }}
            >
              Save
            </LoadingButton>
          </Box>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
