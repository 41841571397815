import { USER_STATUS, User_Role } from './enums';

export const isUserAdmin = (role: any) => {
  if (role === User_Role.admin) {
    return true;
  }
  return false;
};

export const isUserActive = (status: any) => {
  if (status === USER_STATUS.active) {
    return true;
  }
  return false;
};
