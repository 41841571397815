import React, { useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Box,
  IconButton,
  MenuItem,
} from '@mui/material';
import { TableVirtuoso, TableComponents } from 'react-virtuoso';
import { useSnackbar } from 'notistack';
import Iconify from 'src/components/iconify/Iconify';
import MenuPopover from 'src/components/menu-popover/MenuPopover';
import useIgnoredContacts from 'src/hooks/useIgnoredContacts';
import { LoadingButton } from '@mui/lab';
import { deleteDoc, doc } from 'firebase/firestore';
import { DB } from 'src/auth/FirebaseContext';
import EditUserModal from './EditIgnoredContact';
import DeleteContactModal from './DeleteContactModal';

const TableBodyComponent: React.ComponentType<any> = React.forwardRef<HTMLTableSectionElement>(
  (props, ref) => <TableBody {...props} ref={ref} />
);

const ScrollerComponent: React.ComponentType<any> = React.forwardRef<HTMLDivElement>(
  (props, ref) => <TableContainer component={Paper} {...props} ref={ref} />
);

const VirtuosoTableComponents: TableComponents = {
  Scroller: ScrollerComponent,
  Table: (props) => <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />,
  TableHead,
  // eslint-disable-next-line react/prop-types
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: TableBodyComponent,
};

export default function IgnoredContactsTable() {
  const { data } = useIgnoredContacts();

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedIgnoredContactId, setSelectedIgnoredContactId] = useState('');
  const [openDeletionModal, setOpenDeletionModal] = useState(false);
  const [deleteContactLoading, setDeleteContactLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const ignoredContactsData = data?.docs?.map((ignoredContact: any) => ignoredContact.data()) || [];
  useSnackbar();

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>, index: any): void => {
    setOpenPopover(event.currentTarget);
    setSelectedIgnoredContactId(ignoredContactsData[index]?.id);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };
  const handleDelete = async () => {
    if (!selectedIgnoredContactId) return;
    try {
      setDeleteContactLoading(true);
      const docRef = doc(DB, `ignoredContacts`, selectedIgnoredContactId);
      await deleteDoc(docRef);
      setSelectedIgnoredContactId('empty');
      enqueueSnackbar('Contact Deleted', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Contact Deletion Failed', { variant: 'error' });
    } finally {
      setOpenDeletionModal(false);
      setDeleteContactLoading(false);
    }
  };

  const headLabel = [
    { id: 'email', label: 'Email', width: '200px' },
    { id: 'phone', label: 'phone', width: '110px' },
    { id: '', width: '20px' },
  ];

  return (
    <Box style={{ height: '75vh', width: '100%' }}>
      <TableVirtuoso
        data={ignoredContactsData}
        components={VirtuosoTableComponents}
        fixedHeaderContent={() => (
          <TableRow>
            {headLabel.map((head) => (
              <TableCell
                key={head.id}
                align="left"
                style={{ width: head.width }}
                sx={{
                  backgroundColor: 'background.paper',
                }}
              >
                {head.label}
              </TableCell>
            ))}
          </TableRow>
        )}
        itemContent={(index) => (
          <>
            <TableCell style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} align="left">
              {ignoredContactsData[index]?.email}
            </TableCell>
            <TableCell style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} align="left">
              {ignoredContactsData[index]?.phone}
            </TableCell>
            <TableCell align="right">
              <IconButton
                color={openPopover ? 'inherit' : 'default'}
                onClick={(event) => handleOpenPopover(event, index)}
              >
                <Iconify icon="eva:more-vertical-fill" />
              </IconButton>
            </TableCell>
          </>
        )}
      />
      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem
          onClick={() => {
            setOpenEditModal(true);
            handleClosePopover();
          }}
        >
          <Iconify icon="bxs:edit" />
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenDeletionModal(true);
            handleClosePopover();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="eva:trash-2-outline" onClick={handleDelete} />
          Delete
        </MenuItem>
      </MenuPopover>
      <EditUserModal
        EditIgnoredContactModalOpen={openEditModal}
        handleModalClose={() => setOpenEditModal(false)}
        selectedIgnoredContactId={selectedIgnoredContactId}
        setSelectedIgnoredContactId={setSelectedIgnoredContactId}
      />
      <DeleteContactModal
        confirmationModalOpen={openDeletionModal}
        handleCloseModal={() => setOpenDeletionModal(false)}
        title="Ignored Contact Deletion"
        content="Are you sure you want to delete this Ignored Contact?"
      >
        <LoadingButton loading={deleteContactLoading} variant="contained" onClick={handleDelete}>
          Confirm
        </LoadingButton>
      </DeleteContactModal>
    </Box>
  );
}
