import { collection } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { DB } from 'src/auth/FirebaseContext';

const useIgnoredContacts = () => {
  const IgnoredContactsRef = collection(DB as any, `ignoredContacts`);
  const [data, loading] = useCollection(IgnoredContactsRef, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  return { data, loading };
};

export default useIgnoredContacts;
