import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, MenuItem } from '@mui/material';
import { addDoc, collection } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { DB } from 'src/auth/FirebaseContext';
import { RHFSelect, RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';
import Iconify from 'src/components/iconify/Iconify';

type FormValuesProps = {
  name: string;
  service: string;
  templateId: string;
  serviceId: string;
};

interface AddChannModalProps {
  channelModalOpen: boolean;
  handleModalClose: VoidFunction;
}

export default function AddChannelModal({
  channelModalOpen,
  handleModalClose,
}: AddChannModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [isChannelLoading, setIsChannelLoading] = useState(false);

  const channelSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    service: Yup.string().required('Service is required'),
    templateId: Yup.string().required('Template Id is required'),
    serviceId: Yup.string().required('Service Id is required'),
  });

  const defaultValues = {
    name: '',
    service: '',
    templateId: '',
    serviceId: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(channelSchema),
    defaultValues,
  });

  const { reset, handleSubmit, watch } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      setIsChannelLoading(true);
      const colRef = collection(DB, `channels`);
      addDoc(colRef, {
        name: data.name,
        service: data.service,
        templateId: data.templateId,
        serviceId: data.serviceId,
      });
      enqueueSnackbar('Channel Created Successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Channel Creation Failed', { variant: 'error' });
    } finally {
      handleModalClose();
      setIsChannelLoading(false);
      reset();
    }
  };

  const resetModalData = () => {
    handleModalClose();
    reset();
  };

  return (
    <Dialog open={channelModalOpen} onClose={resetModalData}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pt: 1.4,
          pr: 0.3,
          minWidth: '500px !important',
        }}
      >
        Add Channel
        <IconButton onClick={resetModalData}>
          <Iconify icon="radix-icons:cross-2" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <RHFTextField name="name" label="Name" sx={{ mt: '10px' }} />
          <RHFSelect name="service" label="Channel Type" sx={{ mt: '10px' }}>
            <MenuItem value="SMS">SMS</MenuItem>
            <MenuItem value="EMAIL">EMAIL</MenuItem>
          </RHFSelect>
          <RHFTextField
            name="templateId"
            label={watch('service').trim().toLowerCase() === 'email' ? 'Template Id' : 'AI Prompt'}
            sx={{ mt: '10px' }}
          />
          <RHFTextField
            name="serviceId"
            label={
              watch('service').trim().toLowerCase() === 'email'
                ? 'Sender Email'
                : 'Twilio Message Service'
            }
            sx={{ mt: '10px' }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton
              variant="contained"
              loading={isChannelLoading}
              type="submit"
              sx={{ my: 3 }}
            >
              Save
            </LoadingButton>
          </Box>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
