import {
  Divider,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { format } from 'date-fns';
import { addDoc, collection, deleteDoc, doc, getDoc, setDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import Iconify from 'src/components/iconify/Iconify';
import MenuPopover from 'src/components/menu-popover/MenuPopover';
import { useCampaigns } from 'src/hooks/useCampaigns';
import { DB } from 'src/auth/FirebaseContext';
import DeleteConfirmationModal from 'src/sections/chat/header/DeleteConfirmationModal';
import useCampaignParameters from 'src/hooks/useCampaignParameters';
import useSubstitutionParameters from 'src/hooks/useSubstitutionParameters';
import { useChat } from 'src/context/ChatContext';
import CampaignsTableHead from './CampaignsTableHead';
import CampaignEditModal from './CampaignEditModal';
import TableLoader from './TableLoader';

// =================================================================================================
const headLabel = [
  { id: 'campaignName', label: 'Conversation Name' },
  { id: 'campaignAlias ', label: 'Conversation Alias' },
  { id: 'agentProjectId ', label: 'Agent Project Id' },
  { id: 'createdAt ', label: 'Created At' },
  { id: '' },
];

// ===================================================================================================

export default function CampaignsTable() {
  const { enqueueSnackbar } = useSnackbar();
  const { setCampaignID } = useChat();
  const { campaignsData, campaignsDataLoading } = useCampaigns();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState('');
  const [openDeletionModal, setOpenDeletionModal] = useState(false);
  const [campaignDeletionLoading, setCampaignDeletionLoading] = useState(false);

  const handleCampaignDeletion = async () => {
    if (!selectedCampaignId) return;
    try {
      setCampaignDeletionLoading(true);
      const docRef = doc(DB, 'campaigns', selectedCampaignId);
      await deleteDoc(docRef);
      setCampaignID('empty');
      enqueueSnackbar('Conversation Deleted', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Conversation Deletion Failed', { variant: 'error' });
    } finally {
      setOpenDeletionModal(false);
      setCampaignDeletionLoading(false);
    }
  };

  const cloneCampaign = async (
    campaignId: any,
    parameterSetupData: any,
    substitutionParametersData: any
  ) => {
    try {
      const campaignRef = collection(DB as any, `campaigns`);
      const campaignDocRef = doc(campaignRef, campaignId);
      const campaignDocSnapshot = await getDoc(campaignDocRef);
      const campaignData = campaignDocSnapshot.data();
      const clonedCampaignRef = await addDoc(campaignRef, {
        ...campaignData,
        campaignAlias: `${campaignData?.campaignAlias}_cloned`,
        campaignName: `${campaignData?.campaignName}_cloned`,
        createdAt: new Date(),
      });

      const clonedCampaignId = (await getDoc(clonedCampaignRef)).id;
      parameterSetupData.map(async (single: any) => {
        const userRef = doc(
          collection(DB, `campaigns/${clonedCampaignId}/parameterSetup`),
          single.id
        );
        const res = await setDoc(userRef, {
          name: single.name,
          values: single.values,
        });
        return res;
      });

      const substitutionParametersRef = collection(
        DB as any,
        `campaigns/${clonedCampaignId}/substitutionParameters`
      );
      substitutionParametersData.map((single: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { id, ...rest } = single;
        return addDoc(substitutionParametersRef, {
          ...rest,
          value: single.value || null,
          createdAt: new Date(),
        });
      });

      enqueueSnackbar('Conversation Cloned Successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Conversation Cloning Failed', { variant: 'error' });
    }
  };

  return (
    <>
      <TableContainer sx={{ overflow: 'hidden', height: '100%' }}>
        <Table sx={{ minWidth: 720 }}>
          <CampaignsTableHead headLabel={headLabel} />

          <TableBody>
            {campaignsDataLoading ? (
              <TableLoader headLabel={headLabel} />
            ) : (
              campaignsData?.map((row: any) => (
                <CampaignsRow
                  key={row?.id}
                  row={row}
                  handleModalActions={(id: string) => {
                    setSelectedCampaignId(id);
                    setOpenEditModal(true);
                  }}
                  setOpenDeletionModal={setOpenDeletionModal}
                  setSelectedCampaignId={setSelectedCampaignId}
                  cloneCampaign={cloneCampaign}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CampaignEditModal
        openEditModal={openEditModal}
        handleCloseEditModal={() => setOpenEditModal(false)}
        selectedCampaignData={campaignsData.find((data: any) => data.id === selectedCampaignId)}
      />
      <DeleteConfirmationModal
        confirmationModalOpen={openDeletionModal}
        handleCloseModal={() => setOpenDeletionModal(false)}
        title="Conversation Deletion"
        content="Are you sure you want to delete this Conversation?"
      >
        <LoadingButton
          loading={campaignDeletionLoading}
          variant="contained"
          onClick={handleCampaignDeletion}
        >
          Confirm
        </LoadingButton>
      </DeleteConfirmationModal>
    </>
  );
}

// ==========================================================================================================

type CampaignsRowProps = {
  row: any;
  handleModalActions: (v: string) => void;
  setOpenDeletionModal: (v: boolean) => void;
  setSelectedCampaignId: (v: string) => void;
  cloneCampaign: (v: any, x: any, y: any) => void;
};

function CampaignsRow({
  row,
  handleModalActions,
  setOpenDeletionModal,
  setSelectedCampaignId,
  cloneCampaign,
}: CampaignsRowProps) {
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const { data: parameterSetupData } = useCampaignParameters(row.id);
  const { fetchedSubstitutionParameters: substitutionParametersData } = useSubstitutionParameters(
    row.id
  );
  const date = new Date(row.createdAt.seconds * 1000 + row.createdAt.nanoseconds / 1000000);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleEdit = () => {
    handleModalActions(row?.id);
    handleClosePopover();
  };

  const handleDelete = () => {
    setSelectedCampaignId(row?.id);
    setOpenDeletionModal(true);
    handleClosePopover();
  };

  const handleClone = () => {
    setSelectedCampaignId(row?.id);
    cloneCampaign(row?.id, parameterSetupData, substitutionParametersData);
    handleClosePopover();
  };

  return (
    <>
      <TableRow>
        <TableCell>{row?.campaignName || ''}</TableCell>
        <TableCell>{row?.campaignAlias || ''}</TableCell>
        <TableCell>{row?.agentProjectId || ''}</TableCell>
        <TableCell>{format(date, 'yyyy-MM-dd HH:mm:ss') || ''}</TableCell>
        <TableCell align="right">
          <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem onClick={handleEdit}>
          <Iconify icon="bxs:edit" />
          Edit
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
          <Iconify icon="eva:trash-2-outline" />
          Delete
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleClone}>
          <Iconify icon="clarity:clone-line" />
          Clone
        </MenuItem>
      </MenuPopover>
    </>
  );
}
