import { useEffect, useState } from 'react';
import { collection, query, onSnapshot, FirestoreError } from 'firebase/firestore';
import { DB } from 'src/auth/FirebaseContext';

interface Channels {
  id: string;
  [key: string]: any;
}
export const useChannels = () => {
  const [data, setData] = useState<Channels[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<FirestoreError | null>(null);

  useEffect(() => {
    const channelsRef = query(collection(DB, 'channels'));
    const unsubscribe = onSnapshot(
      channelsRef,
      {
        includeMetadataChanges: true,
      },
      (snapshot) => {
        const channels = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setData(channels);
        setLoading(false);
      },
      (err: FirestoreError) => {
        setError(err);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  return { ChannelData: data, loading, error };
};
