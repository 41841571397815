import { FirestoreError, collection, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { DB } from 'src/auth/FirebaseContext';

const useReport = () => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<FirestoreError | null>(null);

  useEffect(() => {
    const reportRef = collection(DB, 'configuration');
    const unsubscribe = onSnapshot(
      reportRef,
      {
        includeMetadataChanges: true,
      },
      (snapshot) => {
        if (snapshot.docs.length > 0) {
          const reportData = snapshot.docs[0].data().report;
          setData(reportData);
        } else {
          setData(null);
        }
        setLoading(false);
      },
      (err: FirestoreError) => {
        setError(err);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  return { data, loading, error };
};

export default useReport;
