// @mui
import { Link, Stack, Typography, useTheme } from '@mui/material';
// sections
import AuthResetPasswordForm from 'src/sections/auth/AuthResetPasswordForm';
import { siteName } from 'src/utils/siteVariables';
// routes
import { PATH_AUTH } from '../../routes/paths';
// layouts
import CompactLayout from '../../layouts/compact';

// ----------------------------------------------------------------------
// @ts-ignore
ResetPasswordPage.getLayout = (page: React.ReactElement) => <CompactLayout>{page}</CompactLayout>;

// ----------------------------------------------------------------------

export default function ResetPasswordPage() {
  const theme = useTheme();
  return (
    <>
      <head>
        <title> Reset Password | {siteName}</title>
      </head>
      <img
        src="/logo/logo.png"
        alt="logo"
        width={150}
        style={{ position: 'absolute', top: '40px', left: '50px' }}
      />
      <Stack alignItems="center" justifyContent="center" height="90%">
        <Stack width={470}>
          <Typography variant="h3" paragraph>
            Forgot your password?
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 5 }}>
            Please enter the email address associated with your account and We will email you a link
            to reset your password.
          </Typography>

          <AuthResetPasswordForm />

          <Link
            href={PATH_AUTH.login}
            color={theme.palette.primary.main}
            variant="subtitle2"
            sx={{
              mt: 3,
              mx: 'auto',
              alignItems: 'center',
              display: 'inline-flex',
              fontSize: 20,
            }}
          >
            Back
          </Link>
        </Stack>
      </Stack>
    </>
  );
}
