import { FirestoreError, collection, onSnapshot } from 'firebase/firestore';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { IFormValuesProps } from 'src/sections/notifications/utils';
import { DB } from 'src/auth/FirebaseContext';

const useNotifications = (campaignId: string) => {
  const [fetchedNotifications, setFetchedNotifications] = useState<IFormValuesProps[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<FirestoreError | any>(null);

  useEffect(() => {
    const notificationsRef = collection(DB, `campaigns/${campaignId}/notifications`);
    const unsubscribe = onSnapshot(
      notificationsRef,
      {
        includeMetadataChanges: true,
      },
      (snapshot) => {
        const notificationsData: IFormValuesProps[] = snapshot.docs.map((doc) => {
          const fetchedItem = doc.data();
          return {
            id: fetchedItem.id,
            name: fetchedItem.name,
            service: fetchedItem.service,
            dateAndTime: dayjs(fetchedItem.dateAndTime.seconds * 1000),
            templateId: fetchedItem.templateId,
            group: fetchedItem.group,
            isPassed: dayjs(fetchedItem.dateAndTime.seconds * 1000).toDate() < new Date(),
          };
        });
        setFetchedNotifications(notificationsData);
        setLoading(false);
      },
      (err: FirestoreError) => {
        setError(err);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [campaignId]);

  return { fetchedNotifications, loading, error };
};

export default useNotifications;
