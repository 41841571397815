import { Box, Chip, IconButton, Stack } from '@mui/material';
import Iconify from 'src/components/iconify/Iconify';
import AddNewParameterModal from './AddNewParameterModal';

export default function ParameterValuesSelect({
  values,
  handleDelete,
  singleItem,
  campaignId,
}: any) {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          border: 1,
          borderColor: 'text.secondary',
          borderRadius: 1,
          p: 1.5,
        }}
      >
        <Stack direction="row" flexWrap="wrap" spacing={0}>
          {values.map((single: any, index: any) => (
            <Chip label={single} sx={{ mt: 0.5, mr: 0.5 }} color="primary" key={index} />
          ))}
        </Stack>
      </Box>
      <AddNewParameterModal singleItem={singleItem} campaignId={campaignId}>
        <IconButton color="primary" aria-label="delete" size="large">
          <Iconify sx={{ cursor: 'pointer' }} icon="fluent:clipboard-text-edit-20-regular" />
        </IconButton>
      </AddNewParameterModal>
      <IconButton aria-label="delete" size="large" color="error" onClick={handleDelete}>
        <Iconify sx={{ cursor: 'pointer' }} icon="ic:baseline-delete" />
      </IconButton>
    </Box>
  );
}
