/* eslint-disable no-nested-ternary */
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormContext, useFieldArray, Controller } from 'react-hook-form';
import { RHFSelect, RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import { useChannels } from 'src/hooks/useChannels';
import { notificationobj, serviceType } from './utils';

export default function NotificationFormDetails({ loading, errors }: any) {
  const { ChannelData } = useChannels();
  const { control, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  const handleAdd = () => {
    append(notificationobj);
  };

  const handleRemove = (index: number) => {
    if (index !== 0) {
      remove(index);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Stack divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />} spacing={3}>
        {fields &&
          fields.map((item, index) => (
            <Stack key={item.id} alignItems="flex-end" spacing={1.5}>
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
                <RHFSelect
                  name={`items[${index}].name`}
                  size="medium"
                  label="Name"
                  sx={{
                    width: '33%',
                  }}
                  // @ts-ignore
                  disabled={Boolean(fields[index]?.isPassed)}
                  onChange={(e) => {
                    setValue(`items[${index}].name`, e.target.value);

                    const selectedChannel = ChannelData.find(
                      (channel: any) => channel.name === e.target.value
                    );

                    if (selectedChannel) {
                      setValue(`items[${index}].service`, selectedChannel.service);
                      setValue(`items[${index}].templateId`, selectedChannel.templateId);
                    }
                  }}
                >
                  <Divider sx={{ borderStyle: 'dashed' }} />
                  {ChannelData.map((_: any, key: any) => (
                    <MenuItem key={key} value={_.name}>
                      {_.name}
                    </MenuItem>
                  ))}
                </RHFSelect>

                <RHFSelect
                  name={`items[${index}].service`}
                  size="medium"
                  label="Service"
                  sx={{
                    width: '33%',
                  }}
                  // @ts-ignore
                  disabled={Boolean(fields[index]?.isPassed)}
                  onChange={(e) => {
                    setValue(`items[${index}].service`, e.target.value);
                  }}
                >
                  <Divider sx={{ borderStyle: 'dashed' }} />
                  {serviceType.map((_, key: any) => (
                    <MenuItem key={key} value={_.value}>
                      {_.label}
                    </MenuItem>
                  ))}
                </RHFSelect>

                <Controller
                  name={`items[${index}].dateAndTime`}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label="Date & Time"
                        defaultValue={field.value}
                        onChange={(newValue: any) => {
                          field.onChange(newValue);
                        }}
                        // @ts-ignore
                        disabled={Boolean(fields[index]?.isPassed)}
                        sx={{ width: '33%' }}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!error,
                            helperText: error?.message,
                          },
                        }}
                        disablePast
                      />
                    </LocalizationProvider>
                  )}
                />

                <RHFTextField
                  size="medium"
                  name={`items[${index}].templateId`}
                  label={
                    'Template Id'
                    // watch(`items[${index}].service`.trim().toLowerCase()) === 'email'
                    //   ? 'Template Id'
                    //   : watch(`items[${index}].service`.trim().toLowerCase()) === 'sms'
                    //   ? 'Event Id'
                    //   : ''
                  }
                  sx={{ width: '33% !important' }}
                  // @ts-ignore
                  disabled={Boolean(fields[index]?.isPassed)}
                />

                <RHFTextField
                  name={`items[${index}].group`}
                  size="medium"
                  label="Group"
                  sx={{
                    width: '33%',
                  }}
                  // @ts-ignore
                  disabled={Boolean(fields[index]?.isPassed)}
                />
              </Stack>

              {index !== 0 && (
                <Button
                  size="small"
                  color="error"
                  startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
                  onClick={() => handleRemove(index)}
                  // @ts-ignore
                  disabled={Boolean(fields[index]?.isPassed)}
                >
                  Remove
                </Button>
              )}
            </Stack>
          ))}
      </Stack>

      <Divider sx={{ my: 3, borderStyle: 'dashed' }} />
      <Stack
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
        spacing={3}
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'flex-end', md: 'center' }}
      >
        <Button
          size="small"
          color="primary"
          startIcon={<Iconify icon="mingcute:add-line" />}
          onClick={handleAdd}
          sx={{ flexShrink: 0 }}
        >
          Add Item
        </Button>

        <Button
          size="small"
          variant="contained"
          color="primary"
          type="submit"
          sx={{ flexShrink: 0 }}
          disabled={errors.items}
        >
          {loading ? <CircularProgress sx={{ color: 'white' }} size={20} /> : 'Submit'}
        </Button>
      </Stack>
    </Box>
  );
}
