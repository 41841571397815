import { FirestoreError, collection, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { DB } from 'src/auth/FirebaseContext';

interface substitutionParameters {
  id: string;
  name: string;
  type: string;
  value: string;
  alias: string;
}

const useSubstitutionParameters = (campaignId: string) => {
  const [fetchedSubstitutionParameters, setFetchedSubstitutionParameters] = useState<
    substitutionParameters[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<FirestoreError | null>(null);

  useEffect(() => {
    const substitutionParameterRef = collection(
      DB,
      `campaigns/${campaignId}/substitutionParameters`
    );

    const unsubscribe = onSnapshot(
      substitutionParameterRef,
      {
        includeMetadataChanges: true,
      },
      (snapshot) => {
        const parameters = snapshot.docs.map((item) => {
          const fetchedItem = item.data();
          return {
            id: item.id,
            name: fetchedItem.name,
            type: fetchedItem.type,
            value: fetchedItem.value,
            alias: fetchedItem.alias,
          };
        });
        setFetchedSubstitutionParameters(parameters);
        setLoading(false);
      },
      (err) => {
        setError(err);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [campaignId]);

  return { fetchedSubstitutionParameters, loading, error };
};

export default useSubstitutionParameters;
