// routes
import Iconify from 'src/components/iconify/Iconify';
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  chat: icon('ic_chat'),
};

export const adminNavConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      { title: 'Chat', path: PATH_DASHBOARD.chat, icon: ICONS.chat },
      {
        title: 'Upload',
        path: PATH_DASHBOARD.upload,
        icon: <Iconify icon="solar:upload-linear" />,
      },
      {
        title: 'Notification',
        path: PATH_DASHBOARD.notificationsetup,
        icon: <Iconify icon="mingcute:notification-fill" />,
      },
      {
        title: 'Setup',
        path: PATH_DASHBOARD.setup,
        icon: <Iconify icon="material-symbols:settings-suggest" />,
        children: [
          { title: 'Conversations', path: PATH_DASHBOARD.conversations },
          { title: 'Personalization', path: PATH_DASHBOARD.personalizationVariables },
          { title: 'Parameter', path: PATH_DASHBOARD.parametersetup },
          { title: 'Notification Channel', path: PATH_DASHBOARD.notificationChannel },
          { title: 'Twilio Service', path: PATH_DASHBOARD.twilioService },
          {
            title: 'Users',
            path: PATH_DASHBOARD.users,
            // icon: <Iconify icon="mdi:users-outline" />,
          },
          {
            title: 'Ignored Contacts',
            path: PATH_DASHBOARD.ignoredContacts,
            // icon: <Iconify icon="mdi:users-outline" />,
          },
        ],
      },
    ],
  },
];

export const supportNavConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [{ title: 'Chat', path: PATH_DASHBOARD.chat, icon: ICONS.chat }],
  },
];
