import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogContent, DialogTitle, Box, IconButton } from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { DB } from 'src/auth/FirebaseContext';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';
import Iconify from 'src/components/iconify/Iconify';

type FormValuesProps = {
  campaignAlias: string;
  campaignName: string;
  agentProjectId: string;
};

interface CampaignEditModalProps {
  openEditModal: boolean;
  handleCloseEditModal: () => void;
  selectedCampaignData: any;
}

export default function CampaignEditModal({
  openEditModal,
  handleCloseEditModal,
  selectedCampaignData,
}: CampaignEditModalProps) {
  const [isCampaignLoading, setIsCampaignLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const campaignSchema = Yup.object().shape({
    campaignName: Yup.string().required('Conversation Name is required'),
    agentProjectId: Yup.string().required('Project ID is required'),
  });

  const defaultValues = {
    campaignName: '',
    agentProjectId: 'staging-early-enrollment-hlcw',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(campaignSchema),
    defaultValues,
  });

  const { reset, handleSubmit } = methods;

  useEffect(() => {
    reset({
      campaignName: selectedCampaignData?.campaignName || '',
      agentProjectId: selectedCampaignData?.agentProjectId || 'staging-early-enrollment-hlcw',
    });
  }, [reset, selectedCampaignData]);

  const onSubmit = async (data: FormValuesProps) => {
    const newdata: FormValuesProps = {
      campaignAlias: data?.campaignName.toLowerCase().replace(/\s+/g, '_'),
      campaignName: data?.campaignName,
      agentProjectId: data?.agentProjectId,
    };

    try {
      setIsCampaignLoading(true);
      const docRef = doc(DB, 'campaigns', selectedCampaignData?.id);
      await updateDoc(docRef, newdata);
      enqueueSnackbar('Conversation Update Successful', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Conversation Update Failed', { variant: 'error' });
    } finally {
      handleCloseEditModal();
      setIsCampaignLoading(false);
    }
  };

  return (
    <Dialog open={openEditModal} onClose={handleCloseEditModal}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pt: 1.4,
          pr: 0.3,
          width: '500px !important',
        }}
      >
        Edit Conversation
        <IconButton onClick={handleCloseEditModal}>
          <Iconify icon="radix-icons:cross-2" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <RHFTextField name="campaignName" label="Conversation Name" sx={{ mt: '10px' }} />
          <RHFTextField name="agentProjectId" label="Agent Project Id" sx={{ mt: '20px' }} />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton
              variant="contained"
              loading={isCampaignLoading}
              type="submit"
              sx={{ my: 3 }}
            >
              Save
            </LoadingButton>
          </Box>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
