/* eslint-disable no-nested-ternary */
import { Button, CircularProgress, Grid, Stack, Typography, useTheme } from '@mui/material';
import { deleteDoc, doc } from 'firebase/firestore';
import { DB } from 'src/auth/FirebaseContext';
import useCampaignParameters from 'src/hooks/useCampaignParameters';
import { useChat } from 'src/context/ChatContext';
import CampaignSelector from './CampaignSelector';
import AddNewParameterModal from './AddNewParameterModal';
import ParameterValuesSelect from './ParameterValuesSelect';

function ParameterSetup() {
  const theme = useTheme();
  const { campaignID } = useChat();
  const { data, loading } = useCampaignParameters(campaignID);
  const handleDelete = async (name: any) => {
    const docName = name.toLowerCase().replace(' ', '_');
    await deleteDoc(doc(DB as any, 'campaigns', campaignID, 'parameterSetup', `${docName}`));
  };

  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        px: 2,
        overflow: 'auto',
        '::-webkit-scrollbar': {
          display: 'none',
        },
        mt: -2,
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
        <CampaignSelector sx={{ width: '40%' }} />
        <AddNewParameterModal campaignId={campaignID}>
          <Button disabled={campaignID === 'empty'} variant="outlined">
            Add New
          </Button>
        </AddNewParameterModal>
      </Stack>
      <Stack sx={{ padding: 2, marginTop: 2, height: '100%' }}>
        {loading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <CircularProgress />
          </div>
        ) : campaignID !== 'empty' ? (
          data?.length !== 0 ? (
            <>
              {data?.map((single: any, index: any) => (
                <Grid container spacing={2} key={index} marginTop={1}>
                  <Grid item xs={12} md={4} lg={4} alignItems="flex-start" justifyContent="center">
                    <Typography
                      variant="h6"
                      sx={{ justifyContent: { xs: 'start', md: 'center', lg: 'center' } }}
                      height="100%"
                    >
                      {single?.name} :
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8} lg={8}>
                    <ParameterValuesSelect
                      values={single?.values}
                      handleDelete={() => handleDelete(single?.name)}
                      singleItem={single}
                      campaignId={campaignID}
                    />
                  </Grid>
                </Grid>
              ))}
            </>
          ) : (
            <Typography
              display="flex"
              justifyContent="center"
              alignItems="center"
              variant="h6"
              height="100%"
              color={theme.palette.primary.main}
            >
              NO PARAMETER CREATED YET
            </Typography>
          )
        ) : (
          <Typography
            display="flex"
            justifyContent="center"
            alignItems="center"
            variant="h6"
            height="100%"
            color={theme.palette.primary.main}
          >
            Please Select a conversation to move forward
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

export default ParameterSetup;
