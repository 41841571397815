export enum USER_STATUS {
  active = 'active',
  inActive = 'inActive',
}
export enum User_Role {
  admin = 'admin',
  support = 'support',
}
export enum Progress_Status {
  start = 'start',
  completed = 'completed',
}
