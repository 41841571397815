/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import CreateUserModal from './CreateIgnoredContact';
import IgnoredContactsTable from './IgnoredContactsTable';

export default function IgnoredContacts() {
  const [addIgnoredContactModalOpen, setaddIgnoredContactModalOpen] = useState(false);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4">IgnoredContacts</Typography>
        <Button
          size="medium"
          variant="contained"
          sx={{ mr: 3 }}
          onClick={() => setaddIgnoredContactModalOpen(true)}
        >
          Create User
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <IgnoredContactsTable />
      </Box>
      <CreateUserModal
        addIgnoredContactModalOpen={addIgnoredContactModalOpen}
        handleModalClose={() => setaddIgnoredContactModalOpen(false)}
      />
    </>
  );
}
