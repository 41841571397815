import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { DB } from 'src/auth/FirebaseContext';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';
import Iconify from 'src/components/iconify/Iconify';
import useIgnoredContacts from 'src/hooks/useIgnoredContacts';

type FormValuesProps = {
  email: string;
  phone: string;
};

interface EditIgnoredContactModalProps {
  EditIgnoredContactModalOpen: boolean;
  handleModalClose: VoidFunction;
  selectedIgnoredContactId: string;
  setSelectedIgnoredContactId: Dispatch<SetStateAction<string>>;
}

export default function EditUserModal({
  EditIgnoredContactModalOpen,
  handleModalClose,
  selectedIgnoredContactId,
  setSelectedIgnoredContactId,
}: EditIgnoredContactModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { data: allIgnoredContactsData } = useIgnoredContacts();

  const [isIgnoredContactLoading, setisIgnoredContactLoading] = useState(false);

  const ignoredContactDocs = allIgnoredContactsData?.docs
    ?.filter((item) => item.id === selectedIgnoredContactId)?.[0]
    ?.data();

  const EditIgnoredContactSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    phone: Yup.string()
      .required('Phone Number is required.')
      .matches(/^\d{10}$/, 'Must be a US number with exactly 10 digits'),
  });

  const defaultValues = {
    email: '',
    phone: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(EditIgnoredContactSchema),
    defaultValues,
  });

  const { reset, handleSubmit } = methods;
  const onSubmit = async (data: FormValuesProps) => {
    setisIgnoredContactLoading(true);
    try {
      const docRef = doc(DB, 'ignoredContacts', selectedIgnoredContactId);
      await updateDoc(docRef, {
        email: data.email,
        phone: data.phone,
      });
      enqueueSnackbar('Ignored Contact Edited Successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error.response.data || 'Operation Failed', { variant: 'error' });
    } finally {
      handleModalClose();
      setSelectedIgnoredContactId('');
      setisIgnoredContactLoading(false);
      reset();
    }
  };

  const resetModalData = () => {
    handleModalClose();
    reset();
  };

  useEffect(() => {
    methods.setValue('email', ignoredContactDocs?.email);
    methods.setValue('phone', ignoredContactDocs?.phone);
  }, [ignoredContactDocs, methods]);

  return (
    <Dialog open={EditIgnoredContactModalOpen} onClose={resetModalData}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pt: 1.4,
          pr: 0.3,
          minWidth: '500px !important',
        }}
      >
        Edit Contact
        <IconButton onClick={resetModalData}>
          <Iconify icon="radix-icons:cross-2" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container justifyContent="space-between">
            <Grid item md={12}>
              <RHFTextField name="email" label="Email" sx={{ mt: '10px' }} />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item md={12}>
              <RHFTextField name="phone" label="Phone" sx={{ mt: '10px' }} />
            </Grid>
          </Grid>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton
              variant="contained"
              loading={isIgnoredContactLoading}
              type="submit"
              sx={{ my: 3 }}
            >
              Save
            </LoadingButton>
          </Box>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
