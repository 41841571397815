import { Autocomplete, FormControl, SxProps, TextField } from '@mui/material';
import { DocumentData, QueryDocumentSnapshot, collection } from 'firebase/firestore';
import { useEffect } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DB } from 'src/auth/FirebaseContext';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useChat } from 'src/context/ChatContext';
import useEffectSkipFirst from 'src/hooks/useEffectSkipFirst';
import { User_Role } from 'src/utils/enums';

interface ComponentProps {
  sx?: SxProps;
}
const CampaignSelector = ({ sx }: ComponentProps) => {
  const [campaigns, loading] = useCollection(collection(DB, 'campaigns'));
  const { profile } = useAuthContext();
  const [searchParams] = useSearchParams();
  const { contactID } = useChat();
  const { changeCampaignID, changeContactID, campaignID } = useChat();
  const ParamsCampaignId = searchParams.get('conversationId') || campaignID;
  const navigate = useNavigate();

  const campaignOptions =
    profile?.role === User_Role.support
      ? profile?.campaigns
          ?.map((item: any) => {
            const matchingCampaign = campaigns?.docs.find(
              (single: QueryDocumentSnapshot<DocumentData>) => single.id === item
            );
            return { label: matchingCampaign?.data().campaignName, value: matchingCampaign?.id };
          })
          .sort((a: any, b: any) => (a.label || '').localeCompare(b.label || ''))
      : campaigns?.docs
          ?.map((single: QueryDocumentSnapshot<DocumentData>) => ({
            label: single.data().campaignName,
            value: single?.id,
          }))
          .sort((a: any, b: any) => a.label.localeCompare(b.label));

  useEffect(() => {
    if (ParamsCampaignId !== 'empty' && campaigns?.docs?.length)
      changeCampaignID({
        target: {
          value: ParamsCampaignId,
        },
      });
    else if (campaignID !== 'empty' && contactID === null) {
      navigate({
        pathname: window.location.pathname,
        search: `?conversationId=${campaignID}`,
      });
    }
  }, [ParamsCampaignId, changeCampaignID, campaigns, navigate, contactID, campaignID]);

  useEffectSkipFirst(() => {
    changeContactID(null);
  }, [ParamsCampaignId, changeContactID]);


  if (!campaignOptions) {
    return null;
  }

  return (
    <FormControl sx={sx} fullWidth size="small">
      <Autocomplete
        id="campaigns"
        loading={loading}
        renderInput={(params) => <TextField {...params} label="Conversations" variant="outlined" />}
        options={campaignOptions?.length ? campaignOptions : []}
        onChange={(e, value) => {
          changeCampaignID({
            target: {
              value: value.value,
            },
          });
          navigate({
            pathname: window.location.pathname,
            search: `?conversationId=${value.value}`,
          });
        }}
        disableClearable
        value={campaignOptions?.find((option: any) => option.value === ParamsCampaignId) || null}
      />
    </FormControl>
  );
};

export default CampaignSelector;
