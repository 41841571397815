import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Card from '@mui/material/Card';
// components
import { useSnackbar } from 'notistack';
import { addDoc, collection, deleteDoc, getDocs } from 'firebase/firestore';

import dayjs from 'dayjs';
import FormProvider from 'src/components/hook-form';
import { DB } from 'src/auth/FirebaseContext';
import NotificationFormDetails from './NotificationFormDetails';
import { IFormValuesProps, Props, notificationobj } from './utils';

// ----------------------------------------------------------------------

export default function NotificationForm({ items, campaignId }: Props) {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  // =======================================================================
  const handleFormSubmit = async (data: any) => {
    const NotificationData = data.items;
    const campaignRef = collection(DB as any, `campaigns/${campaignId}/notifications`);

    try {
      setLoading(true);

      const query = await getDocs(campaignRef);

      const deletePromises = query.docs.map((item: any) => deleteDoc(item.ref));
      await Promise.all(deletePromises);

      await Promise.all(
        NotificationData.map(async (item: any) => {
          await addDoc(campaignRef, {
            name: item.name,
            service: item.service,
            dateAndTime: dayjs(item.dateAndTime).toDate(),
            templateId: item.templateId,
            group: item.group,
          });
        })
      );

      setLoading(false);
      enqueueSnackbar('Notification Added Successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error adding documents:', error);
      setLoading(false);
    }
  };

  // =======================================================================

  const NewNotificationSchema = Yup.object().shape({
    items: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Name is required'),
        service: Yup.string().required('Service is required'),
        // eslint-disable-next-line func-names
        dateAndTime: Yup.date().test('End Data', 'Enter valid date', function (date) {
          // eslint-disable-next-line react/no-this-in-sfc
          const { isPassed } = this.parent;
          if ((date && date > new Date()) || isPassed) return true;
          return false;
        }),
        templateId: Yup.string().required('Id is required'),
        group: Yup.string().required('Group is required'),
      })
    ),
  });

  const defaultValues: Props = useMemo(() => {
    const initialValues: IFormValuesProps[] = (items || []).map((item) => ({
      id: item.id,
      name: item.name,
      service: item.service,
      dateAndTime: item.dateAndTime,
      templateId: item.templateId,
      group: item.group,
      isPassed: item.isPassed,
    }));

    return {
      items: initialValues.length ? initialValues : [notificationobj],
    };
  }, [items]);

  const methods = useForm<Props>({
    resolver: yupResolver(NewNotificationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    // watch,
    formState: { errors },
  } = methods;
  useEffect(() => {
    if (!loading) {
      setValue('items', defaultValues.items);
    }
  }, [defaultValues, setValue, loading]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(handleFormSubmit)}>
      <Card>
        <NotificationFormDetails loading={loading} errors={errors} />
      </Card>
    </FormProvider>
  );
}
