import {
  Divider,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { deleteDoc, doc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import Iconify from 'src/components/iconify/Iconify';
import MenuPopover from 'src/components/menu-popover/MenuPopover';
import { DB } from 'src/auth/FirebaseContext';
import DeleteConfirmationModal from 'src/sections/chat/header/DeleteConfirmationModal';
import { useChat } from 'src/context/ChatContext';
import { useChannels } from 'src/hooks/useChannels';
import CampaignsTableHead from '../Campaigns/CampaignsTableHead';
import TableLoader from '../Campaigns/TableLoader';
import ChannelEditModal from './ChannelEditModal';

const headLabel = [
  { id: 'name', label: 'Name' },
  { id: 'service ', label: 'Service' },
  { id: 'templateId ', label: 'Template Id' },
  { id: 'serviceId ', label: 'Service Id' },
  { id: '' },
];

export default function ChannelTable() {
  const { enqueueSnackbar } = useSnackbar();
  const { setCampaignID } = useChat();
  const { ChannelData, loading } = useChannels();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedChannelId, setSelectedChannelId] = useState('');
  const [openDeletionModal, setOpenDeletionModal] = useState(false);
  const [channelDeletionLoading, setChannelDeletionLoading] = useState(false);

  const handleCampaignDeletion = async () => {
    if (!selectedChannelId) return;
    try {
      setChannelDeletionLoading(true);
      const docRef = doc(DB, `channels`, selectedChannelId);
      await deleteDoc(docRef);
      setCampaignID('empty');
      enqueueSnackbar('Channel Deleted', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Channel Deletion Failed', { variant: 'error' });
    } finally {
      setOpenDeletionModal(false);
      setChannelDeletionLoading(false);
    }
  };

  return (
    <>
      <TableContainer sx={{ overflow: 'hidden', height: '100%' }}>
        <Table sx={{ minWidth: 720 }}>
          <CampaignsTableHead headLabel={headLabel} />
          <TableBody>
            {loading ? (
              <TableLoader headLabel={headLabel} />
            ) : (
              ChannelData?.map((row: any) => (
                <CampaignsRow
                  key={row?.id}
                  row={row}
                  handleModalActions={(id: string) => {
                    setSelectedChannelId(id);
                    setOpenEditModal(true);
                  }}
                  setOpenDeletionModal={setOpenDeletionModal}
                  setSelectedChannelId={setSelectedChannelId}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <ChannelEditModal
        openEditModal={openEditModal}
        handleCloseEditModal={() => setOpenEditModal(false)}
        selectedChannelData={ChannelData.find((data: any) => data.id === selectedChannelId)}
      />
      <DeleteConfirmationModal
        confirmationModalOpen={openDeletionModal}
        handleCloseModal={() => setOpenDeletionModal(false)}
        title="Channel Deletion"
        content="Are you sure you want to delete this Channel?"
      >
        <LoadingButton
          loading={channelDeletionLoading}
          variant="contained"
          onClick={handleCampaignDeletion}
        >
          Confirm
        </LoadingButton>
      </DeleteConfirmationModal>
    </>
  );
}

type CampaignsRowProps = {
  row: any;
  handleModalActions: (v: string) => void;
  setOpenDeletionModal: (v: boolean) => void;
  setSelectedChannelId: (v: string) => void;
};

function CampaignsRow({
  row,
  handleModalActions,
  setOpenDeletionModal,
  setSelectedChannelId,
}: CampaignsRowProps) {
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleEdit = () => {
    handleModalActions(row?.id);
    handleClosePopover();
  };

  const handleDelete = () => {
    setSelectedChannelId(row?.id);
    setOpenDeletionModal(true);
    handleClosePopover();
  };

  return (
    <>
      <TableRow>
        <TableCell>{row?.name || ''}</TableCell>
        <TableCell>{row?.service || ''}</TableCell>
        <TableCell>{row?.templateId || ''}</TableCell>
        <TableCell>{row?.serviceId || ''}</TableCell>
        <TableCell align="right">
          <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem onClick={handleEdit}>
          <Iconify icon="bxs:edit" />
          Edit
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
          <Iconify icon="eva:trash-2-outline" />
          Delete
        </MenuItem>
      </MenuPopover>
    </>
  );
}
