/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import {
  Grid,
  TextField,
  InputLabel,
  Button,
  Stack,
  CircularProgress,
  Typography,
  useTheme,
  Tooltip,
  IconButton,
} from '@mui/material';

import { deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { DB } from 'src/auth/FirebaseContext';
import useSubstitutionParameters from 'src/hooks/useSubstitutionParameters';
import Iconify from 'src/components/iconify';
import { useChat } from 'src/context/ChatContext';
import CampaignSelector from './components/CampaignSelector';
import AddSubstitutionParameterModal, {
  SUBSTITUTION_PARAMETER_TYPES,
} from './components/SubstitutionParameters/AddSubstitutionParameterModal';

export type SubstitutionParameter = {
  id: String;
  value: String;
  type: String;
  name: String;
};
function SubstitutionParameters() {
  const [SubstitutionParameterModalOpen, setSubstitutionParameterModalOpen] = useState(false);
  const theme = useTheme();
  const { campaignID } = useChat();

  const handleBlur = async (id: String, newValue: String) => {
    if (newValue.trim() !== '') {
      const docRef = doc(DB as any, `campaigns/${campaignID}/substitutionParameters/${id}`);
      await updateDoc(docRef, { value: newValue });
    }
  };
  const handleDelete = async (id: any) => {
    await deleteDoc(doc(DB as any, `campaigns/${campaignID}/substitutionParameters/${id}`));
  };
  const { fetchedSubstitutionParameters, loading } = useSubstitutionParameters(campaignID);

  return (
    <>
      <Stack
        spacing={2}
        sx={{
          width: '100%',
          height: '100%',
          px: 2,
          overflow: 'auto',
          '::-webkit-scrollbar': {
            display: 'none',
          },
          mt: -1,
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
          <CampaignSelector sx={{ width: '30%', mt: '10px' }} />

          <Stack direction="row" justifyContent="flex-end" sx={{ width: '59%', mt: '10px' }}>
            <Button
              variant="outlined"
              disabled={campaignID === 'empty'}
              onClick={() => setSubstitutionParameterModalOpen(true)}
            >
              Add Variable
            </Button>
          </Stack>
        </Stack>

        <Stack sx={{ padding: 2, marginTop: 2, height: '100%' }}>
          {loading ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
              }}
            >
              <CircularProgress />
            </div>
          ) : campaignID !== 'empty' ? (
            fetchedSubstitutionParameters.length > 0 ? (
              JSON.parse(JSON.stringify(fetchedSubstitutionParameters))
                .sort((a: SubstitutionParameter) => (a.type === 'STATIC' ? -1 : 1))
                .map((item: SubstitutionParameter) => (
                  <Grid container spacing={1} key={item.id as any} flexDirection={'row'} mb={1}>
                    <Grid sx={{ display: 'flex', alignItems: 'center' }} item xs={12} md={2}>
                      <InputLabel>{item.name}</InputLabel>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <TextField
                        sx={{ width: '50%' }}
                        defaultValue={item.value}
                        id="outlined-basic"
                        variant="outlined"
                        disabled={item.type === SUBSTITUTION_PARAMETER_TYPES.STATIC}
                        onBlur={(e) => handleBlur(item.id, e.target.value)}
                      />
                      {item.type === SUBSTITUTION_PARAMETER_TYPES.STATIC ? (
                        <Tooltip title="This is a Static Parameter" arrow>
                          <IconButton>
                            <Iconify icon="bx:lock" height={25} width={25} color={'#919EAB'} />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <IconButton
                          aria-label="delete"
                          size="large"
                          color="error"
                          onClick={() => handleDelete(item.id)}
                        >
                          <Iconify sx={{ cursor: 'pointer' }} icon="ic:baseline-delete" />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                ))
            ) : (
              <Typography
                display="flex"
                justifyContent="center"
                alignItems="center"
                variant="h6"
                height="100%"
                color={theme.palette.primary.main}
              >
                NO PARAMETERS SELECTED YET
              </Typography>
            )
          ) : (
            <Typography
              display="flex"
              justifyContent="center"
              alignItems="center"
              variant="h6"
              height="100%"
              color={theme.palette.primary.main}
            >
              Please Select a conversation to move forward
            </Typography>
          )}
        </Stack>
      </Stack>
      <AddSubstitutionParameterModal
        campaignId={campaignID}
        SubstitutionParameterModalOpen={SubstitutionParameterModalOpen}
        handleModalClose={() => setSubstitutionParameterModalOpen(false)}
      />
    </>
  );
}

export default SubstitutionParameters;
