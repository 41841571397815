import { collection, query, orderBy, onSnapshot, FirestoreError } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { DB } from 'src/auth/FirebaseContext';

interface Campaigns {
  id: string;
  [key: string]: any;
}

export const useCampaigns = () => {
  const [campaignsData, setCampaignsData] = useState<Campaigns[]>([]);
  const [campaignsDataLoading, setCampaignsDataLoading] = useState(true);
  const [campaignsError, setCampaignsError] = useState<FirestoreError | null>();

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(DB, 'campaigns'), orderBy('createdAt', 'desc')),
      {
        includeMetadataChanges: true,
      },
      (snapshot) => {
        const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setCampaignsData(data);
        setCampaignsDataLoading(false);
      },
      (error) => {
        setCampaignsError(error);
        setCampaignsDataLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  return { campaignsData, campaignsDataLoading, campaignsError };
};
