// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
  register: '/register',
  verifyOTP: '/verify-otp',
  phoneNumber: '/get-phone-number',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  chat: path(ROOTS_DASHBOARD, '/chat'),
  upload: path(ROOTS_DASHBOARD, '/upload'),
  notificationsetup: path(ROOTS_DASHBOARD, '/notificationsetup'),
  setup: path(ROOTS_DASHBOARD, '/setup'),
  users: path(ROOTS_DASHBOARD, '/setup/users'),
  conversations: path(ROOTS_DASHBOARD, '/setup/conversations'),
  personalizationVariables: path(ROOTS_DASHBOARD, '/setup/personalizationVariables'),
  notificationChannel: path(ROOTS_DASHBOARD, '/setup/notificationChannel'),
  twilioService: path(ROOTS_DASHBOARD, '/setup/twilioService'),
  parametersetup: path(ROOTS_DASHBOARD, '/setup/parametersetup'),
  ignoredContacts: path(ROOTS_DASHBOARD, '/setup/ignoredContacts'),
};
